.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  width: 400px;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content label {
  display: block;
  margin: 10px 0 5px;
}

.user-stats {
  margin-bottom: 20px;
}

.user-stats h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.user-stats p {
  font-size: 1rem;
  margin: 5px 0;
}

.crab-ratings h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.crab-ratings table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.crab-ratings th,
.crab-ratings td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.crab-ratings th {
  background-color: #4caf50;
  color: white;
}

.crab-ratings tr:nth-child(even) {
  background-color: #f2f2f2;
}

.crab-ratings tr:hover {
  background-color: #ddd;
}
