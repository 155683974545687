/* Overview container styles */
.overview-container {
  color: black;
  background-color: white;
}

/* Greeting and Date Section */

.greeting {
  font-size: 25px;
}

.date {
  color: #888;
}
/* Inactive Account Message */
.inactive-account-message {
  background-color: #90cfe4;
  color: black;
  padding: 1rem;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.inactive-account-message p {
  margin: 0;
  font-size: 1.1rem;
}

.deposit-button {
  padding: 10px;
  font-size: 1rem;
  background-color: rgb(245, 107, 114);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

/* Cards Section */
.cards-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background-color: #f7f9fc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 90%;
}

.card-icon {
  font-size: 2rem;
  margin-right: 15px;
}

.blue-icon {
  color: #1e90ff;
}

.green-icon {
  color: #28a745;
}

.orange-icon {
  color: #ff8c00;
}

.card-content .card-label {
  font-size: 0.875rem;
}

.card-content .card-value {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Transactions and Portfolio Section */
.details-container {
  display: flex;
  gap: 20px;
}

.transactions,
.portfolio {
  width: 100%;
}

.section-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Transaction Card Styles */
.transaction-card {
  background-color: #f7f9fc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.transaction-info {
  display: flex;
  align-items: center;
}

.transaction-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.transaction-type {
  font-size: 1rem;
  font-weight: 600;
}

.transaction-date,
.transaction-amount .status {
  color: #888;
}

.transaction-amount .amount.deposit {
  color: #28a745;
}

.transaction-amount .amount.withdrawal {
  color: #ff8c00;
}

/* Portfolio Card Styles */
.portfolio-card {
  background-color: #f7f9fc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.asset-image {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.portfolio-info {
  font-size: 0.875rem;
}

.no-transactions,
.no-assets {
  color: #888;
}
