.auction-header {
  margin-bottom: 20px;
  color: blue;
}

.auction-header h1 {
  font-size: 2rem;
  color: #333;
}

.auction-rules h2,
.current-auction h2,
.place-bid h2,
.my-bids h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #444;
}

.auction-rules ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}

.auction-rules li {
  margin-bottom: 8px;
  font-size: 1rem;
}

.current-auction p {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.place-bid ol {
  list-style-type: decimal;
  margin-left: 20px;
  color: #555;
}

.place-bid li {
  margin-bottom: 8px;
  font-size: 1rem;
}

.my-bids p {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}
