.deposit-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.info-section ul {
  list-style: none;
  padding: 0;
}

.info-section li {
  margin-bottom: 10px;
}

.methods-section {
  margin-top: 30px;
}

.methods-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.method-card {
  text-align: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  flex: 1 1 200px;
  max-width: 200px;
}

.method-card h3 {
  margin: 10px 0;
}

.payment-btn {
  background-color: #b2b2b2;
  color: rgb(83, 81, 81);
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-btn:hover {
  background-color: #45a049;
}

.qr-section {
  text-align: center;
  margin-top: 20px;
}

.qr-section .wallet-address {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
}

.history-section {
  margin-top: 30px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
}

.history-table th,
.history-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}
