.loginDiv1 {
  display: flex;
  background-image: url(../images/background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  min-height: 650px;
}

.loginDiv2 {
  padding-top: 30px;
  padding-bottom: 60px;
  width: 100%;
  background-color: #66747f52;
  display: flex;
  justify-content: center;
}

.loginDiv2b {
  width: 40%;
  background-color: var(--white);
  padding: 30px;
  padding-top: 50px;
  border-radius: 10px;
}

.loginDiv21 h1 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  margin-top: -5px;
  margin-top: 20px;
}

.loginDiv21 h3 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  margin-top: -15px;
  font-size: 12px;
}

.loginDiv22 {
  margin-top: 50px;
  display: block;
}

.loginDiv22 h3 {
  color: gray;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  font-size: 13px;
}

.loginDiv22 input {
  padding: 12px;
  width: 90%;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: var(--white);
  color: var(--black);
}

.loginDiv22 h4 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  text-align: right;
  width: 100%;
}

.loginBtn {
  background: rgb(245, 107, 114);
  background: linear-gradient(
    67deg,
    rgba(245, 107, 114, 1) 0%,
    rgba(237, 86, 94, 1) 50%,
    rgba(235, 59, 73, 1) 100%
  );
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  width: 55%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;
  margin-top: 40px;
  border: none;
}

.loginBtn h3 {
  color: var(--white);
  margin-top: 0px;
  font-size: 16px;
}

.registerBtn {
  border: 2px solid #f56b72;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  width: 40%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;
  margin-top: 40px;
  background-color: var(--white);
}

.registerBtn h3 {
  color: var(--black);
  margin-top: 0px;
  font-size: 16px;
}

.loginDiv23 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.loginDiv23 hr {
  width: 25%;
  background-color: gray;
  border: none;
  height: 2px;
}

.loginDiv23 h4 {
  margin-top: -5px;
  color: gray;
}

.loginDiv24 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.loginDiv24 h3 {
  color: var(--black);
}

@media screen and (max-width: 925px) {
  .loginDiv1 {
    display: flex;
    min-height: 932px;
  }

  .loginDiv2 {
    padding: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .loginDiv2b {
    width: 90%;
    background-color: var(--white);
    padding: 40px;
    padding-top: 80px;
    border-radius: 10px;
  }

  .loginDiv21 h1 {
    font-size: 20px;
    margin-top: 0px;
  }

  .loginDiv21 h3 {
    margin-top: -5px;
    font-size: 13px;
  }

  .loginDiv22 {
    margin-top: 40px;
  }

  .loginDiv22 h3 {
    margin-top: 12px;
    font-size: 16px;
  }

  .loginDiv22 h4 {
    font-size: 10px;
  }

  .loginBtn {
    padding: 5px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-top: 20px;
  }

  .loginBtn h3 {
    margin-top: 0px;
    font-size: 13px;
  }

  .loginDiv24 h3 {
    font-size: 13px;
  }
}

.faq-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.faq-title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.faq-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faq-item {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.faq-question {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.faq-answer {
  font-size: 16px;
  line-height: 1.5;
  color: #555;
}

.rules-page {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.rules-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* .rules-content {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
} */

.rules-list {
  list-style: decimal;
  padding-left: 20px;
}

.rules-list li {
  margin-bottom: 15px;
}

.nested-list {
  list-style: disc;
  padding-left: 20px;
  margin-top: 10px;
}

.nested-list ul {
  list-style: circle;
  padding-left: 20px;
}

.highlight-red {
  color: red;
  font-weight: bold;
}

.wholesale-title {
  font-size: 28px;
  margin-bottom: 20px;
}

.wholesale-content {
  padding: 20px;
  border-radius: 8px;
}

.wholesale-content p {
  margin-bottom: 20px;
}

.wholesale-list {
  list-style: none;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.wholesale-list li {
  margin-bottom: 10px;
}

.wholesale-note {
  margin-top: 20px;
  font-size: 16px;
}
