/* General styling */
.profile-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

/* Profile picture */
.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Verified status */
.profile-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.verified-status {
  display: flex;
  align-items: center;
  color: green;
}

.verified-icon {
  margin-right: 0.5rem;
}

.verify-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Profile form */
.profile-form {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
  grid-template-columns: 1fr;
}

.profile-input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Save changes button */
.save-button {
  background-color: var(--blue);
  color: white;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  max-width: 500px;
}

/* Responsive styling */
@media (max-width: 640px) {
  .profile-form {
    grid-template-columns: 1fr;
  }

  .profile-title {
    font-size: 1.25rem;
  }

  .profile-input,
  .save-button {
    font-size: 0.9rem;
  }
}
