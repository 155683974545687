/* .lottery-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  font-family: Arial, sans-serif;
} */

.lottery-header {
  margin-bottom: 20px;
  color: blue;
}

.lottery-terms ul {
  list-style: disc;
  margin: 20px 0;
  padding-left: 40px;
}

.lottery-details,
.lottery-winners {
  margin-top: 30px;
}

.lottery-details table,
.lottery-winners table {
  width: 100%;
  /* border-collapse: collapse; */
  margin-top: 10px;
}

.lottery-details table td,
.lottery-winners table td {
  padding: 10px;
  border: 1px solid #ddd;
  vertical-align: top;
}

.lottery-details table tr:nth-child(even),
.lottery-winners table tr:nth-child(even) {
  background-color: #f9f9f9;
}
