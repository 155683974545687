/* Container */
.dashboard-layout {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Button links container */
.button-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 1rem 0;
}

.nav-button {
  padding: 15px;
  font-size: 1rem;
  background-color: #bdbdbd;
  color: #616161;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Layout adjustments for mobile */
@media (max-width: 768px) {
  .button-links {
    /* flex-direction: column; */
  }

  .nav-button {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Main content styling */
.main-content {
  width: 100%;
  margin-top: 20px;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.dropdown-item {
  padding: 10px 20px;
  background: white;
  color: black;
  text-align: left;
  border: none;
  width: 100%;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f0f0f0;
}
