.registerDiv1 {
  display: flex;
  background-image: url(../images/background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
}

.registerDiv2 {
  padding-top: 30px;
  padding-bottom: 60px;
  width: 100%;
  background-color: #66747fbc;
  display: flex;
  justify-content: center;
}

.registerDiv21 h1 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  margin-top: -5px;
  margin-top: 20px;
}

.registerDiv21 h3 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  margin-top: -15px;
  font-size: 12px;
}

.registerDiv22 {
  margin-top: 20px;
  display: block;
}

.registerDiv22 h3 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}

.password-toggle-icon {
  align-self: center;
  font-size: 15px;
  margin-right: 5px;
}

.registerDiv22 input {
  padding: 12px;
  width: 90%;
  border-radius: 5px;
  border: 1px solid var(--black);
  background-color: var(--white);
  color: var(--black);
}

.registerDiv22 h4 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  text-align: right;
  width: 100%;
}

.registerDiv23 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.registerDiv23 hr {
  width: 25%;
  background-color: gray;
  border: none;
  height: 2px;
}

.registerDiv23 h4 {
  margin-top: -5px;
  color: gray;
}

.registerDiv24 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.registerDiv24 h3 {
  color: var(--black);
}

@media screen and (max-width: 925px) {
  .registerDiv1 {
    display: flex;
    min-height: 932px;
  }

  .registerDiv2 {
    padding: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .registerDiv21 h1 {
    font-size: 20px;
    margin-top: 0px;
  }

  .registerDiv21 h3 {
    margin-top: -5px;
    font-size: 13px;
  }

  .registerDiv22 {
    margin-top: 30px;
  }

  .registerDiv22 h3 {
    margin-top: 12px;
    font-size: 16px;
  }

  .registerDiv22 input {
    padding: 8px;
    color: var(--indigo);
  }

  .password-input-container {
    display: flex;
    width: 100%;
    border-radius: 10px;
  }

  .registerDiv22 h4 {
    font-size: 10px;
  }

  .registerBtn {
    padding: 5px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-top: 20px;
    width: 100%;
  }

  .registerBtn h3 {
    margin-top: 0px;
    font-size: 13px;
  }

  .registerDiv23 {
    margin-top: 20px;
    margin-left: 10%;
    width: 80%;
  }

  .registerDiv24 h3 {
    font-size: 13px;
  }
}
