/* src/styles/paymentWithdrawalManagement.css */

.adHomeDiv1 {
  padding: 10px;
}

.paymentManagementMain {
  padding: 20px;
  background-color: #f5f5f5;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.paymentManagementHeader {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}
