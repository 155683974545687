.transaction-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #000;
}

.transaction-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #000;
}

.loading-text {
  text-align: center;
  font-size: 1rem;
}

.transaction-card {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s;
  border-left: 4px solid transparent;
}

.transaction-card:hover {
  transform: scale(1.05);
}

.border-green {
  border-color: green;
}

.border-red {
  border-color: red;
}

.transaction-details {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.transaction-icon {
  font-size: 1.5rem;
}

.green {
  color: green;
}

.red {
  color: red;
}

.transaction-type {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.transaction-date {
  color: #666;
  font-size: 0.875rem;
}

.transaction-amount {
  text-align: right;
}

.amount-value {
  font-size: 1.25rem;
  font-weight: 700;
}

.text-green {
  color: #2ecc71;
}

.text-red {
  color: #e74c3c;
}

.status {
  font-size: 0.875rem;
}

.status.confirmed {
  color: green;
}

.status.pending {
  color: orange;
}
